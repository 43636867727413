import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useFormStore } from "../Hooks/store/formStore.js";
import CustomSeparator from "../component/Header/CustomSeparator.jsx";
import MaintenanceCalorieIcon from "../assets/images/marker-orange.png";
import TargetCalorieIcon from "../assets/images/marker-blue.png";
import TargetProteinIcon from "../assets/images/marker-green.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import MainContent from "../component/MainContent.jsx";

// Validation schema
const validationSchema = Yup.object({
  higherCalorieDay: Yup.number()
    .required("This field is required")
    .min(0, "Minimum value is 0")
    .max(3, "Maximum value is 3"),
});

function Result() {
  document.title = "Calorie Calculator: Targets";
  const navigate = useNavigate();
  const { height, weight, maintenanceCalories, targetCalories } = useFormStore(
    (state) => ({
      gender: state.gender,
      dob: state.dob,
      height: state.height,
      weight: state.weight,
      userUnit: state.userUnit,
      maintenanceCalories: state.maintenanceCalories,
      targetCalories: state.targetCalories,
    })
  );
  React.useEffect(() => {
    if (!height || !weight) {
      navigate("/");
    }
  }, [height, weight, navigate]);

  const [higherCalorieDay, setHigherCalorieDay] = useState(0);
  const targetProtein = (targetCalories * 0.17 * 0.25).toFixed(2);

  const highCalorieTarget = (targetCalories * 1.2).toFixed(2);
  let calTargetCalories = targetCalories * 7;
  let calHigherCalories = higherCalorieDay * highCalorieTarget;
  let calTargetCMinHigherC = calTargetCalories - calHigherCalories;
  let calHigherCaloriesDay = 7 - higherCalorieDay;
  let lowCalorieTarget = calTargetCMinHigherC / calHigherCaloriesDay;
  lowCalorieTarget = lowCalorieTarget.toFixed(2);

  // Function to round down by nearest 1
  const roundDownToNearestTen = (num) => {
    return Math.floor(num / 10) * 10;
  };

  // Function to round down by nearest 1
  const roundDownToNearestOne = (num) => {
    return Math.floor(num);
  };

  // const lowCalorieTarget = (
  //   (targetCalories * 7 - higherCalorieDay * highCalorieTarget) / 7 -
  //   higherCalorieDay
  // ).toFixed(2);

  const formik = useFormik({
    initialValues: {
      higherCalorieDay: 0,
    },
    validationSchema, // Attach the validation schema
    onSubmit: (values) => {
      console.log(values);
    },
    validateOnChange: false, // Disable validation on change for smoother UX
    validateOnBlur: true,
  });

  useEffect(() => {
    // Update the state based on formik values
    setHigherCalorieDay(formik.values.higherCalorieDay || "");
  }, [formik.values.higherCalorieDay]);

  const isAffordableCountry = sessionStorage.getItem("isAffordableCountry");

  return (
    <Box
      sx={{
        pb: 2.5,
        px: 2.5,
        width: "100%",
        backgroundColor: "white",
      }}
    >
      {/* custom separator - home - MEASUREMENTS - RESULTS */}
      <Box
        sx={{
          py: 2.5,
        }}
      >
        <CustomSeparator />
      </Box>

      {/* Calories Meter Box */}
      <Box
        className="jaydip"
        display="flex"
        sx={{
          p: 2,
          backgroundColor: "#fdfafa",
          borderRadius: "14px",
        }}
      >
        <Box flexShrink={0}>
          {/* outer Box Orange */}
          <Box
            display="flex"
            alignItems="flex-end"
            sx={{
              width: "26px",
              height: "100%",
              backgroundColor: "transparent",
              border: "4px solid #fa6902",
              borderRadius: "20px",
              position: "relative",
            }}
          >
            {/* inner middle Box Blue */}
            <Box
              display="flex"
              alignItems="flex-end"
              sx={{
                width: "18px",
                height: "73%",
                backgroundColor: "transparent",
                border: "4px solid #0293fa",
                borderRadius: "20px",
                position: "relative",
              }}
            >
              {/* inner Box Green */}
              <Box
                sx={{
                  width: "14px",
                  height: "37%",
                  backgroundColor: "#68d13b",
                  borderRadius: "20px",
                  position: "relative",
                }}
              >
                {/* content here */}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box flexGrow={1} paddingLeft={3.4}>
          {/* Maintenance Calories */}
          <Box
            sx={{
              color: "#fa6902",
              mb: 3,
              position: "relative",
            }}
          >
            {/* Market Icon */}
            <img
              src={MaintenanceCalorieIcon}
              style={{
                display: "block",
                maxWidth: "18px",
                maxHeight: "18px",
                position: "absolute",
                top: "0px",
                left: "-20px",
                rotate: "90deg",
              }}
              alt="icon"
            />

            <Typography
              variant="h4"
              fontSize={16}
              fontWeight="700"
              sx={{ mb: 0.6 }}
            >
              Maintenance Calories
            </Typography>
            <Typography
              variant="h4"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 0.4 }}
            >
              <Typography
                variant="span"
                fontSize={12}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.2 }}
              >
                {" "}
                Maximum
              </Typography>
              to maintain your current size
            </Typography>

            <Typography
              variant="p"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 1 }}
            >
              <Typography
                variant="span"
                fontSize={24}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.3, fontFamily: "'Fredoka', sans-serif" }}
              >
                {" "}
                {maintenanceCalories}
              </Typography>
              kCal/day
            </Typography>
          </Box>

          {/* Target Calories */}
          <Box
            sx={{
              color: "#0293fa",
              mb: 3,
              position: "relative",
            }}
          >
            {/* Market Icon */}
            <img
              src={TargetCalorieIcon}
              style={{
                display: "block",
                maxWidth: "18px",
                maxHeight: "18px",
                position: "absolute",
                top: "0px",
                left: "-20px",
                rotate: "90deg",
              }}
              alt="target icon"
            />

            <Typography
              variant="h4"
              fontSize={16}
              fontWeight="700"
              sx={{ mb: 0.6 }}
            >
              Target Calories
            </Typography>
            <Typography
              variant="h4"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 0.4 }}
            >
              <Typography
                variant="span"
                fontSize={12}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.2 }}
              >
                {" "}
                Maximum
              </Typography>
              to lose weight sustainably
            </Typography>

            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                variant="p"
                fontSize={12}
                fontWeight="500"
                sx={{ mb: 0, display: "block", color: "#545454" }}
              >
                Higher calorie days:
              </Typography>
              <FormControl
                sx={{ width: "100px" }}
                component="form"
                onSubmit={formik.handleSubmit}
              >
                <Select
                  name="higherCalorieDay"
                  value={formik.values.higherCalorieDay}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setHigherCalorieDay(event.target.value);
                  }}
                  displayEmpty
                  variant="standard"
                  error={
                    formik.touched.higherCalorieDay &&
                    Boolean(formik.errors.higherCalorieDay)
                  }
                  sx={{ mb: 0, width: "70px", textAlign: "center" }}
                >
                  <MenuItem value="" disabled>
                    Select days
                  </MenuItem>
                  {[0, 1, 2, 3].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  sx={{ fontSize: 12, color: "#2196F3" }}
                  align="center"
                  gutterBottom
                ></Typography>
              </FormControl>
            </Box>

            {/* high day  */}
            <Typography
              variant="p"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 0, display: "block" }}
            >
              <Typography
                variant="span"
                fontSize={24}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.3, fontFamily: "'Fredoka', sans-serif" }}
              >
                {" "}
                {higherCalorieDay
                  ? `${roundDownToNearestTen(highCalorieTarget)}`
                  : `${roundDownToNearestTen(targetCalories)}`}
              </Typography>
              {higherCalorieDay ? `kCal/high day` : `kCal/day`}
              {/* kCal/high day */}
            </Typography>

            {/* low day  */}
            <Typography
              variant="p"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 1, display: "block" }}
            >
              {higherCalorieDay && (
                <Typography
                  variant="span"
                  fontSize={24}
                  fontWeight="700"
                  sx={{ mb: 0.4, mr: 0.3, fontFamily: "'Fredoka', sans-serif" }}
                >
                  {" "}
                  {roundDownToNearestTen(lowCalorieTarget)}
                </Typography>
              )}
              {higherCalorieDay && `kCal/low day `}
            </Typography>
          </Box>

          {/* Target Protein */}
          <Box
            sx={{
              mb: 3,
              color: "#00bf63",
              position: "relative",
            }}
          >
            {/* Market Icon */}
            <img
              src={TargetProteinIcon}
              style={{
                display: "block",
                maxWidth: "18px",
                maxHeight: "18px",
                position: "absolute",
                top: "0px",
                left: "-20px",
                rotate: "90deg",
              }}
              alt="target protein icon"
            />

            <Typography
              variant="h4"
              fontSize={16}
              fontWeight="700"
              sx={{ mb: 0.6 }}
            >
              Target Protein
            </Typography>
            <Typography
              variant="h4"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 0.4 }}
            >
              <Typography
                variant="span"
                fontSize={12}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.2 }}
              >
                Minimum{" "}
              </Typography>
              for satiety
            </Typography>

            <Typography
              variant="p"
              fontSize={12}
              fontWeight="500"
              sx={{ mb: 1 }}
            >
              <Typography
                variant="span"
                fontSize={24}
                fontWeight="700"
                sx={{ mb: 0.4, mr: 0.3, fontFamily: "'Fredoka', sans-serif" }}
              >
                {" "}
                {roundDownToNearestOne(targetProtein)}
              </Typography>
              g/day
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* END Calories Meter Box */}

      {/* start regult page design */}
      {isAffordableCountry === "true" ? <MainContent /> : <></>}
      {/* END regult page design */}
    </Box>
  );
}

export default Result;
