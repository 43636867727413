export const affordableCountryCodeList = [
  "+1",
  "+32",
  "+33",
  "+39",
  "+41",
  "+43",
  "+44",
  "+45",
  "+46",
  "+47",
  "+61",
  "+64",
  "+65",
  "+81",
  "+82",
  "+352",
  "+353",
  "+354",
  "+356",
  "+358",
  "+598",
  "+852",
  "+853",
  "+886",
  "+961",
  "+972",
  "+974",
  "+1 246",
  "+1 242",
  "+1 241",
  "+1 787",
  "+1 939",
  "+44 1481",
  "+44 1534",
];
