import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/index";
import Routes from "./Routes";
import "./App.css";
import { useEffect } from "react";
import { affordableCountryCodeList } from "./constant/affordableCountryCodeList";

function App() {
  useEffect(() => {
    async function checkUserCallAffordability() {
      try {
        const response = await fetch(
          "https://us-central1-body-fat-calc-test.cloudfunctions.net/getIpInSight"
        );
        const data = await response.json();
        sessionStorage.setItem("isAffordableCountry", false);
        if (data?.dial_code) {
          const isAffordableCountry = affordableCountryCodeList.includes(
            data.dial_code
          );
          sessionStorage.setItem("isAffordableCountry", isAffordableCountry);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    checkUserCallAffordability();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
